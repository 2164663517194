@import "property.scss";
@import "mixin.scss";
@import "colors.scss";

.Workflow-card {
  border: 1px solid #ced4da;
  border-radius: 8px;
  position: relative;
  & + & {
    margin-top: 40px;
    &:before {
      position: absolute;
      top: -38px;
      left: 50%;
      content: "▼";
      transform: translateX(-50%);
      font-size: 24px;
      color: $MAIN_THEME_COLOR_SECONDARY;
    }
  }
  &__title {
    line-height: 1;
    padding: 8px 0;
    border-radius: 8px 8px 0 0;
    background: #dee2e6;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
  }
  &__steps {
    padding: 10px;
  }
  &__step {
    position: relative;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 10px;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    & + & {
      margin-top: 40px;
      &:before {
        position: absolute;
        top: -33px;
        left: 50%;
        content: "↓";
        transform: translateX(-50%);
        font-size: 20px;
      }
    }
  }
}
