.__Block {
  margin: 0;
}
.__Link {
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.__Icon-wrapper {
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

$HEADER_HEIGHT: 50;

.__Rounded-card {
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #ced4da;
}
