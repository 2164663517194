@import "property.scss";

.List {
  @extend .__Block;
  display: flex;
  &__item {
    & + & {
      margin-left: 10px;
    }
  }
}
