@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.Process-progress-card {
  @extend .__Rounded-card;
  width: 300px;
  font-size: 0.9rem;
  &.--grouping {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  &__title {
    font-size: 0.875rem;
    font-weight: bold;
  }
  &__step {
    display: flex;
    align-items: center;
    &.--link {
      @extend .__Link;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
