@import "colors.scss";
@import "property.scss";

.No-data {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  &__icon {
    width: 64px;
    height: 40px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__children {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
