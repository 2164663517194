@import "colors.scss";
@import "mixin.scss";

.Fixed-text-box {
  padding: 15px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: scroll;
  font-size: 13px;
  max-height: 160px;
  white-space: pre-wrap;
}
