@import "colors.scss";
@import "property.scss";

.Ev-container {
  position: relative;
  padding: 60px 20px 100px;
  min-height: 100svh;
  overflow: scroll;
  &.--editor {
    padding-left: 0;
    border-right: 1px solid #f2f2f2;
  }
}
.Ev-edit {
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    &__nav {
      padding: 10px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.9);
      z-index: 512;
      position: fixed;
      top: 60px;
      left: 230px;
      width: calc(100% - 240px);
    }
    &__preview {
      overflow: scroll;
      margin-left: 10px;
      padding-bottom: 220px;
    }
    .Ev-edit-pane + & {
      margin-left: 230px;
    }
    .Ev-edit-pane--folded + & {
      margin-left: 20px;
      @include res(sm) {
        margin-left: 40px;
      }
    }
  }
  &-pane {
    position: fixed;
    top: 50px;
    left: 0;
    width: 220px;
    height: calc(100svh - 50px);
    z-index: 128;
    background: #fff;
    &__row {
      padding: 10px;
      border-bottom: 1px solid #f2f2f2;
      cursor: pointer;
      &.--current {
        background: #e9f7ff;
        color: #769dc2;
      }
    }
    &--folded {
      display: none;
    }
  }
  &-controls {
    position: fixed;
    bottom: 0;
    left: 230px;
    width: calc(100% - 230px);
    background: rgba(255, 255, 255, 0.94);
    padding: 10px;
    border: 1px solid #ddd;
    z-index: 128;
    .tab-content {
      min-height: 240px;
    }
    &.--expanded {
      left: 0;
      width: 100%;
    }
  }
}

.Ev-unit-groups {
  padding: 20px;
  background: #fff;
  display: inline-block;
  &.--fit-screen {
    width: 100%;
  }
  .--editor & {
    padding: 20px 0 0;
  }
}
.Ev-unit-group {
  &__name {
    font-weight: bold;
    font-size: 0.8rem;
  }
  &__header {
    min-width: 250px;
    max-width: 400px;
    background: #fbf0f0;
    padding: 6px 10px;
    border-radius: 4px;
  }
  &.--editing {
    border-top: 3px solid #d2ddd2;
    padding: 20px 0;
  }
  &__procedure {
    margin-top: 10px;
    &-item {
      background: #fffbfb;
      border-radius: 4px;
      padding: 6px;
      border: 1px solid transparent;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.--expanded {
        border: 1px solid #ff9d9d;
      }
    }
    &-label {
      font-size: 0.8rem;
    }
    &-content {
      overflow: hidden;
      height: 0;
      &.--expanded {
        height: auto;
        overflow: visible;
      }
    }
    &-bottom-controls {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__styles {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  &__style-editor {
    background: #fbf0f0;
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 5px;
    margin-top: 10px;
  }
}
.Ev-unit {
  position: relative;
  transition: all 0.2s ease-in-out;
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    textarea {
      resize: none;
      border-radius: 0;
      border: none;
    }
    select {
      border-radius: 0;
      border: none;
    }
    &.--centering {
      align-items: center;
    }
  }
  &__value {
    width: 100%;
    padding: 10px;
    &.--locked {
      color: #888;
      background: #f2f2f2;
    }
    &.--veiled {
      background: #888;
      text-indent: -9999px;
    }
    .--no-grid &:empty {
      display: none;
    }
  }
  &__switching-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 61;
    &.--focused {
      background: rgba(255, 100, 100, 0.5);
    }
  }
  &.--column-legend {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &.--row-legend {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.--editing {
    &:before {
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 4px solid red;
      content: "";
      z-index: 61;
    }
  }
  &.--context-menu-target {
    &:before {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 2px solid red;
      content: "";
      z-index: 61;
    }
  }
  &__debug {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    background: #f2fff2;
    &.--id {
      bottom: auto;
      top: 0;
      right: 0;
      color: red;
    }
  }
  &__edit-buttons {
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    z-index: 64;
  }
  &__edit-button {
    padding: 2px;
    line-height: 1;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    &.--trigger {
      background: $LINK_TEXT_COLOR;
      color: #fff;
    }
    &.--info {
      background: transparent;
      color: $LINK_TEXT_COLOR;
    }
  }
}
.Ev-unit-variable {
  display: flex;
  align-items: center;
  &__col {
    max-width: 50%;
    width: 400px;
    padding: 0 10px;
  }
}
.Ev-headline {
  font-size: 18px;
  margin-bottom: 0;
}
.Ev-remarks {
  font-size: 12px;
  color: #888;
  margin-top: 6px;
}
.Ev-edit {
  &__group {
    padding: 10px;
    background: #f2fff2;
    border-radius: 5px;
    & + & {
      margin-top: 10px;
    }
  }
  &__sub-group {
    & + & {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px dashed #ddd;
    }
  }
}

.Ev-editor-error {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 256;
  color: red;
  background: rgba(255, 255, 255, 0.9);
  padding: 7px;
  font-size: 0.8rem;
}

.Ev-editor-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;
  border: 1px solid #ddd;
  background: rgba(68, 108, 143, 0.3);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  z-index: 128;
}
