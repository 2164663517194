@import "colors.scss";
@import "constants.scss";
@import "property.scss";
@import "mixin.scss";

.__list {
  background: #fff;
  padding: ($HEADER_HEIGHT) + px 0 20px;
  width: 220px;
  height: 100vh;
  border-right: 1px solid rgba(29, 71, 99, 0.2);
  display: block;
  overflow-y: auto;
}

.Sidebar {
  @extend .__Block;
  position: absolute;
  height: 100vh;
  @include res(md) {
    position: relative;
  }
  &__list {
    @extend .__list;
    &.--evaluation {
      padding-top: 0;
    }
  }
  &__list--folded {
    @extend .__list;
    display: none;
  }
  &__row {
    padding: 12px 0 12px 54px;
    font-size: 14px;
    position: relative;
    color: #222;
    cursor: pointer;
    transition: all 0.2s ease;
    letter-spacing: -0.5px;
    svg {
      color: #777;
    }
    &:hover {
      color: #769dc2;
      background: #e9f7ff;
      svg {
        color: #3793e8;
      }
    }
    &--current {
      color: #769dc2;
      background: $MAIN_THEME_COLOR_BG;
      svg {
        color: #3793e8;
      }
    }
    &--logout {
      &:before {
        content: none;
      }
    }
    &--with-company-choices {
      border-left: 8px solid $MAIN_THEME_COLOR_SECONDARY;
      padding-left: 46px;
    }
    &--sub {
      background: #f2f9ff;
      position: relative;
      padding-top: 8px;
      padding-bottom: 8px;
      cursor: default;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
        content: "";
        background: $MAIN_THEME_COLOR_BG;
      }
      &:hover {
        background: #f2f9ff;
      }
    }
    &-sub-link {
      position: relative;
      display: block;
      text-decoration: none;
      &--current {
        color: #769dc2;
        font-weight: bold;
        cursor: default;
        &:hover {
          color: #769dc2;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -10px;
          width: 3px;
          height: 80%;
          transform: translateY(-50%);
          background: $MAIN_THEME_COLOR_SECONDARY;
        }
      }
    }
  }
  &__row-icon {
    @extend .__Icon-wrapper;
    position: absolute;
    top: 50%;
    left: 22px;
  }
  &__sub-row {
    padding: 6px 0 6px 44px;
    font-size: 0.8rem;
    position: relative;
    color: #777;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  &__sub-row--current {
    color: #0d6efd;
    background: #e0e7f1;
  }
  &__company-switcher {
    display: block;
    background: $MAIN_THEME_COLOR_SECONDARY;
    padding: 5px 0 5px 10px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      color: #fff;
    }
  }
  @include print {
    display: none;
  }
  &__evaluation-edit {
    border-left: 8px solid $MAIN_THEME_COLOR_SECONDARY;
    font-size: 0.8rem;
  }
  &__evaluation-edit-row {
    padding: 5px 10px;
    position: relative;
  }
  &__balloon-list {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 0;
    transform: translate(100%, 0);
    z-index: 1024;
    background: rgba(255, 255, 255, 0.9);
    border-top: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 0 5px 5px 0;
    &-item {
      padding: 5px 10px;
      line-height: 1;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        color: $LINK_TEXT_COLOR;
      }
    }
  }
}
