@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.Todo-card {
  @extend .__Rounded-card;
  background-color: #f8f9fa;
  width: 180px;
  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__name {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  &__portrait {
    width: 32px;
    height: 32px;
    overflow: hidden;
    position: relative;
    margin-right: 6px;
    background: #fff;
    border-radius: 50%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
    }
    &:empty {
      background: #ddd;
    }
  }
}
