@import "property.scss";

.Text {
  @extend .__Block;
  font-size: 14px;
  &__label {
    @extend .__Block;
    font-size: 14px;
    font-weight: bold;
  }
}
