@import "colors.scss";
@import "property.scss";
@import "mixin.scss";

.Stats {
  overflow: scroll;
  &__view {
    width: auto;
  }
  &__header {
    display: flex;
    flex-wrap: nowrap;
  }
  &__header-cell {
    padding: 10px 0;
    position: relative;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1;
    align-content: end;
    &.--legend {
      background: $MAIN_THEME_COLOR_PRIMARY;
      color: #fff;
    }
    &:nth-child(2n) {
      background: #f4f5f6;
    }
    // &:not(:first-child):not(:last-child):before {
    //   position: absolute;
    //   top: 50%;
    //   right: 0;
    //   transform: translate(0, -50%);
    //   width: 1px;
    //   height: 90%;
    //   content: "";
    //   background: #446c8f;
    // }
  }
  &__block {
    &-label {
      font-size: 1rem;
      font-weight: bold;
      background: $MAIN_THEME_COLOR_BG;
      color: $MAIN_THEME_COLOR_PRIMARY;
      padding: 5px 0 5px 20px;
      margin: 0;
      position: relative;
      &:before {
        position: absolute;
        top: 50%;
        left: 6px;
        content: "";
        width: 5px;
        height: 60%;
        transform: translate(0, -50%);
        background: $MAIN_THEME_COLOR_PRIMARY;
        border-radius: 3px;
      }
    }
    &-content {
      &:nth-child(2n) {
        background: #f4f5f6;
      }
    }
    &-content-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      &.--group-start {
        border-top: 1px solid #ddd;
      }
    }
    &-cell {
      padding: 10px 20px;
      font-size: 0.875rem;
      line-height: 1.6;
      &.--legend {
        border-right: 1px solid #ddd;
      }
      &.--compact {
        padding: 0;
      }
      .--thin &:not(.--compact) {
        padding: 2px 20px;
      }
    }
  }
  &__gage {
    position: relative;
    padding: 1px 0;
    &-content {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      z-index: 32;
      overflow: hidden;
    }
    &-label {
      width: 20px;
      text-align: center;
    }
    &-value {
      font-size: 0.65rem;
      position: relative;
      z-index: 32;
    }
    &-line {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80px;
      padding: 0 5px;
      height: 22px;
      font-size: 12px;
      line-height: 22px;
      white-space: nowrap;
      border-radius: 5px;
      overflow: hidden;
      &-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 29;
        align-content: center;
      }
    }
  }
  &__legends {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  &__legend-item {
    position: relative;
    padding-left: 16px;
    display: flex;
    align-items: center;
    margin: 2px;
    &-color {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      content: "";
      width: 10px;
      height: 10px;
      border: 1px solid #777;
    }
    &-value {
      color: #777;
      font-size: 0.875rem;
    }
  }
  &__sub-value {
    color: #777;
  }
}
