@import "colors.scss";
@import "mixin.scss";

.Camera-preview {
  zoom: 0.5;
  height: auto;
  display: none;
  &.--active {
    display: block;
  }
}
