@import "colors.scss";
@import "property.scss";

.Cx-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 65536;
  &__list {
    position: absolute;
    z-index: 65543;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  &__item {
    padding: 8px 0;
    font-size: 0.8rem;
    color: #666;
    cursor: pointer;
    line-height: 1;
    transition: all 0.2s ease;
    &:hover {
      color: $LINK_TEXT_COLOR;
    }
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 65537;
  }
}
